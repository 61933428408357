import { Download } from "@styled-icons/fa-solid"
import { Button } from "primereact/button"
import { useEffect, useState } from "react"

import { Eye } from "@styled-icons/fa-solid"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import Loading from "../../../components/Loading"
import { useToast } from "../../../components/Toast"
import http from "../../../plugins/http"
import ModalApproveProduct from "../ModalApproveProduct/Modal"
import ModalReproachProduct from "../ModalReproachProduct/Modal"
function ModalApproveOrDisapprove({
	product,
	className,
	fetchProducts,
}: {
	product: any
	className?: string
	fetchProducts: () => void
}) {
	const [modal, setModal] = useState(false)

	const [loading, setLoading] = useState(false)
	const [currentProduct, setCurrentProduct] = useState<any>({})
	const toast = useToast()
	const toggle = () => {
		if (!modal) {
			fetchProduct()
		}
		setModal((prevModal) => !prevModal)
	}

	async function reproachProduct(productId: number, form: any) {
		try {
			setLoading(true)
			const justification = form.justification
			const response = await http.put(
				`v1/managers/products/reproach/${productId}`,
				{
					justification,
				},
			)
			if (response.status !== 200) {
				return
			}
			toggle()
			toast.current?.show({
				severity: "success",
				summary: "Mensagem enviada",
				detail: `Sua mensagem foi enviada para o produtor com sucesso. Aguarde retorno ou entre em contato pelos outros meios de comunicação disponíveis em seus dados.`,
			})
		} catch (error) {
			console.log(error)
		} finally {
			fetchProducts()
			setLoading(false)
		}
	}

	async function approveProduct(productId: number, form: any) {
		try {
			setLoading(true)
			const justification = form.justification
			const response = await http.put(
				`v1/managers/products/approve/${productId}`,
				{
					justification,
				},
			)
			if (response.status !== 200) {
				return
			}
			toggle()
			toast.current?.show({
				severity: "success",
				summary: "Aprovado com sucesso",
				detail:
					"O produto cadastrado se encontra de acordo com as normas da plataforma, aprovado com sucesso.",
			})
		} catch (error) {
			console.log(error)
		} finally {
			fetchProducts()
			setLoading(false)
		}
	}

	async function fetchProduct() {
		try {
			setLoading(true)
			const response = await http.get(`v1/products/${product.id}`)
			if (response.status !== 200) {
				return
			}
			setCurrentProduct(response.data)
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	function toMoney(value: number | string) {
		if (typeof value === "string") {
			value = Number.parseFloat(value)
		}
		return Intl.NumberFormat("pt-BR", {
			style: "currency",
			currency: "BRL",
			minimumFractionDigits: 2,
		}).format(value)
	}

	function getPeriods() {
		const periods = product.periods
			?.filter((period: any) => period.qtd != undefined)
			.map((period: any) => {
				const [year, month] = period.date.split("-").map(Number)
				const date = new Date(year, month - 1, 1)
				return {
					date: Intl.DateTimeFormat("pt-BR", {
						year: "numeric",
						month: "long",
					}).format(date),
					peso: period.qtd,
					unit: period.unit,
				}
			})
		return periods
	}

	function urlImage(images: any, index: number) {
		if (images.length <= index) {
			return "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
		}
		if (images[index].url.includes("https://")) {
			return images[index].url
		}
		const { REACT_APP_API_URL } = process.env
		return `${REACT_APP_API_URL}${images[index].url}`
	}

	return (
		<div className='inline-flex'>
			<Eye
				onClick={toggle}
				size={26}
				className='cursor-pointer text-yellow-500 ml-2'
			/>
			<Dialog maximizable visible={modal} onHide={toggle} className={className}>
				{loading ? (
					<div className='grid place-content-center'>
						<Loading color='#479632' size='w-10' />
					</div>
				) : (
					<div className='w-full bg-light px-3 py-5'>
						<div className='grid grid-rows-1 grid-cols-1 md:grid-cols-2 gap-4'>
							<div className='flex flex-col w-full'>
								{currentProduct?.images &&
									currentProduct?.images.length > 0 && (
										<>
											<img
												src={urlImage(currentProduct.images, 0)}
												alt='Castanhas'
												className='w-full'
											/>
											<div className='grid grid-cols-1 md:grid-cols-3 gap-4 mt-3'>
												{currentProduct.images.length > 1 && (
													<img
														src={urlImage(currentProduct.images, 1)}
														className='aspect-w-1 aspect-h-1 min-w-[120px] rounded-md mx-auto'
														alt='castanha'
													/>
												)}
												{currentProduct.images.length > 2 && (
													<img
														src={urlImage(currentProduct.images, 2)}
														className='aspect-w-1 aspect-h-1 min-w-[120px] rounded-md mx-auto'
														alt='castanha'
													/>
												)}
												{currentProduct.images.length > 3 && (
													<img
														src={urlImage(currentProduct.images, 3)}
														className='aspect-w-1 aspect-h-1 min-w-[120px] rounded-md mx-auto'
														alt='castanha'
													/>
												)}
											</div>
											<div className='mt-3 text-center'>
												<span className='subtitle'>
													<b>Descrição do produto:</b>{" "}
													{currentProduct?.description}
												</span>
											</div>
										</>
									)}
							</div>
							<div className='flex flex-col items-center min-w-screen w-full'>
								<div className='w-full'>
									<span className='text-[#146600] text-2xl'>
										{currentProduct?.name}
									</span>
									<h5 className='text-left mt-2 text-muted'>
										ID: <b className='text-[#666666]'>{currentProduct?.id}</b>
									</h5>
									<h5 className='text-left mt-2 text-muted'>
										Tipo:{" "}
										<b className='text-[#666666]'>
											{currentProduct?.product_type?.name}
										</b>
									</h5>
									{currentProduct?.product_subtype && (
										<h5 className='text-left mt-2 text-muted'>
											Subtipo:{" "}
											<b className='text-[#666666]'>
												{currentProduct?.product_subtype?.name}
											</b>
										</h5>
									)}
									<h5 className='text-left text-muted'>
										Quantidade disponível:{" "}
										<b className='text-[#666666]'>
											{currentProduct?.detail?.qtd}{" "}
											{currentProduct?.detail?.unit_of_measurement}
										</b>
									</h5>
									<h5 className='text-left text-muted'>
										Comunidade:{" "}
										<b className='text-[#666666]'>
											{currentProduct?.detail?.community}
										</b>
									</h5>
									<h5 className='text-left text-muted'>
										Cidade:{" "}
										<b className='text-[#666666]'>
											{currentProduct?.city?.name}/
											{currentProduct?.state?.short_name}
										</b>
									</h5>
									<h5 className='text-left text-muted'>
										Preço cadastrado:{" "}
										<b className='text-[#666666]'>
											{toMoney(currentProduct?.base_price)}/
											{currentProduct?.detail?.unit_of_measurement}
										</b>
									</h5>
									<h5 className='mb-2 py-2 text-left fs-2 text-muted'>
										<b className='text-3xl text-[#666666]'>
											{toMoney(currentProduct?.detail?.price)}/
											{currentProduct?.detail?.unit_of_measurement}
										</b>
									</h5>

									{!loading && getPeriods().length === 0 && (
										<p>Sem previsão de capacidade de entrega cadastrada</p>
									)}
									{!loading && getPeriods().length > 0 && (
										<>
											<DataTable
												header='Previsão de capacidade de entrega'
												showGridlines
												value={getPeriods()}
												className='font-roboto text-sm'
											>
												<Column field='date' header='Mês' />
												<Column
													body={(row) => (
														<span>
															{row?.peso} {row?.unit}
														</span>
													)}
													header='Peso'
												/>
											</DataTable>
										</>
									)}
									<div className='pt-5'>
										{!loading && currentProduct?.certificates?.length === 0 && (
											<p>Sem certificados cadastrados</p>
										)}
										{!loading &&
											currentProduct?.certificates?.length > 0 &&
											currentProduct?.certificates?.map((item, i) => (
												<li key={i} className='pb-5'>
													{item.name}
													<div className='flex items-center space-x-10 pt-5'>
														<object
															data={item.data}
															type=''
															className='w-[300px] max-h-[150px]'
														>
															<div>
																se não estiver sendo exibido recomendo que faça
																o&nbsp;
																<a href={item.data} download={item.filename}>
																	<u className='text-[#28A745]'>download</u>
																</a>
																.
															</div>
														</object>
														<a href={item.data} download={item.filename}>
															<Download className='w-5 h-5 text-black'></Download>
														</a>
													</div>
												</li>
											))}
									</div>
								</div>
							</div>
						</div>
						{product?.status !== 0 && (
							<div className='w-full flex justify-end gap-4 py-5'>
								{product?.status !== 2 && (
									<ModalApproveProduct
										productId={currentProduct?.id}
										approve={approveProduct}
									/>
								)}
								{product?.status !== 3 && (
									<ModalReproachProduct
										productId={currentProduct?.id}
										disapprove={reproachProduct}
									/>
								)}
							</div>
						)}
					</div>
				)}
			</Dialog>
		</div>
	)
}

export default ModalApproveOrDisapprove

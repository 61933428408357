import React from "react";
import type { Group } from "./Groups.d";

const groups: Group[] = [
  {
    value: "parameters",
    label: "Parâmetros",
    component: React.lazy(() => import("../Tables/Parameters/Parameters")),
  },
  {
    value: "financial",
    label: "Financeiro",
    subgroups: [
      {
        label: 'Agente Financeiro',
        value: 'financial-agent',
        component: React.lazy(() => import("../Tables/Financeiro/FinancialAgent")),
      },
      {
        label: 'Trader',
        value: 'trader',
        component: React.lazy(() => import("../Tables/Financeiro/Trader")),
      },
      {
        label: 'Vendedores',
        value: 'buyer',
        component: React.lazy(() => import("../Tables/Financeiro/Sellers")),
      },
    ]
  },
  // {
  //   value: "register",
  //   label: "Cadastro",
  //   component: React.lazy(() => import("../Tables/Cadastro/Cadastro")),
  // },
  {
    value: "supplier",
    label: "Fornecedores",
  },
  {
    value: "buyer",
    label: "Compradores",
  },
  {
    value: "logistics",
    label: "Logística",
  },
  {
    value: "dashboards",
    label: "Dashboards",
    component: React.lazy(() => import("../Tables/Dashboards/Dashboards")),
  },
  {
    value: "faqs",
    label: "FAQ",
    component: React.lazy(() => import('../Tables/Faq/Faq'))
  },
  {
    value: "Unidade de medida",
    label: "Unidade de medida",
    component: React.lazy(() => import("../Tables/Measurement/index")),
  },
  {
    value: "Tipos e subtipos",
    label: "Tipos e subtipos",
    component: React.lazy(() => import("../Tables/TypesAndSubTypes/index")),
  },
  {
    value:"Termos de privacidade",
    label:"Termos de privacidade",
    component: React.lazy(() => import("../Tables/TermsPrivacies/TermsPrivacies")),
  },
  {
    value: "others",
    label: "Outros",
  },
];

export default groups;

import React from 'react'

interface IErrorMessage {
  message: string
}
const ErrorMessage: React.FC<IErrorMessage> = ({ message }: IErrorMessage) => (
  <div className="text-[#DC3545] mt-1 font-sans Roboto font-normal text-xs">
    {message}
  </div>
)

export default ErrorMessage

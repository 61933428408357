import { useCallback, useEffect, useRef, useState } from "react"
import http from "../../../plugins/http"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import Loading from "../../../components/Loading"
import { useToast } from "../../../components/Toast"
import { Message } from "primereact/message"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dropdown } from "primereact/dropdown"
import { BreadCrumb } from "primereact/breadcrumb"
import { exportBlobToFile } from "../../../utils/save"
import { toMoney } from "../../../utils/money"
import { dateBR } from "../../../utils/data"
import { Tooltip } from "primereact/tooltip"
import { Dialog } from "primereact/dialog"

const THROTTLE_DELAY = 30000

const SalesPage: React.FC = () => {
	const today = new Date()
	const prevMonth = new Date()
	prevMonth.setMonth(today.getMonth() - 1)
	const toast = useToast()
	const [sales, setSales] = useState([])
	const [loading, setLoading] = useState(false)
	const [productGroups, setProductGroups] = useState<any[]>([])
	const [productTypes, setProductTypes] = useState<any[]>([])
	const [productSubtypes, setProductSubtypes] = useState<any[]>([])
	const [isEmailDisabled, setIsEmailDisabled] = useState<{
		[key: number]: boolean
	}>({})
	const [dialogVisible, setDialogVisible] = useState(false)
	const [newStatus, setNewStatus] = useState(null)
	const [selectedSale, setSelectedSale] = useState<{
		id: number
		status: number
	} | null>(null)
	const lastEmailSentTime = useRef<{ [key: number]: number }>({})
	const [filters, setFilters] = useState({
		dateInitial: prevMonth.toISOString().split("T")[0],
		dateFinal: new Date().toISOString().split("T")[0],
		page: 1,
		perPage: 100,
		product_group: null,
		product_type: null,
		product_subtype: null,
		status: 5,
		detail: true,
	})

	const statusOptions = [
		{ public_name: "Todos", value: null },
		{ public_name: "proposta de venda", value: 1 },
		{ public_name: "Aprovado porém, sem link de pagamento", value: 2 },
		{ public_name: "Reprovado pelo vendedor", value: 3 },
		{ public_name: "Link de pagamento gerado, mas sem pagamento", value: 4 },
		{ public_name: "Pago", value: 5 },
	]

	const userSituation = [
		{ public_name: "Todos", value: null },
		{ public_name: "Ativados", value: 1 },
		{ public_name: "Desativados", value: 0 },
	]

	const fetchSales = async (filter: any) => {
		try {
			setLoading(true)
			const response = await http.post("v1/reports/sales", filter)
			if (response.status === 200) {
				setSales(response.data)
			}
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	async function fetchProductGroups() {
		try {
			setLoading(true)
			const response = await http.get("v1/product-groups?all=1")
			if (response.status === 200) {
				setProductGroups([{ id: null, name: "Todos" }, ...response.data])
			}
			return []
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	async function fetchProductTypes() {
		try {
			setLoading(true)
			const responseTypes = await http.get("v1/product-types?all=1")
			if (responseTypes.status === 200) {
				return responseTypes.data
			}
			return []
		} catch (error) {
			console.log(error)
			return []
		} finally {
			setLoading(false)
		}
	}

	async function fetchProductSubtypes() {
		try {
			setLoading(true)
			const responseSubtypes = await http.get("v1/product-subtypes?all=1")
			if (responseSubtypes.status === 200) {
				return responseSubtypes.data
			}
			return []
		} catch (error) {
			console.log(error)
			return []
		} finally {
			setLoading(false)
		}
	}

	const handleStatusChange = useCallback((rowData: any) => {
		setSelectedSale(rowData)
		setNewStatus(rowData.status)
		setDialogVisible(true)
	}, [])

	const changeStatus = () => {
		if (selectedSale && newStatus !== null) {
			http
				.put(`v1/reports/sales-status-change/${selectedSale.id}`, {
					status: newStatus,
				})
				.then((response) => {
					if (response.status === 200) {
						toast.current?.show({
							severity: "success",
							summary: "Success",
							detail: "Status de venda atualizado com sucesso",
						})
						fetchSales(filters)
					}
				})
				.catch((error) => {
					console.error(error)
					toast.current?.show({
						severity: "error",
						summary: "Error",
						detail: "Erro ao atualizar status de venda",
					})
				})
		}
		setDialogVisible(false)
	}

	const cancelStatusChange = () => {
		setDialogVisible(false)
	}

	const renderFooter = () => {
		return (
			<div className='flex justify-end gap-x-5'>
				<Button
					onClick={cancelStatusChange}
					disabled={loading}
					severity='danger'
				>
					Cancelar
				</Button>
				<Button
					type='submit'
					onClick={changeStatus}
					disabled={loading}
					severity='success'
				>
					{loading && <Loading />}
					Mudar status da compra
				</Button>
			</div>
		)
	}

	useEffect(() => {
		fetchSales(filters)
		fetchProductGroups()
	}, [])

	const handleFilterChange = (e: any) => {
		const { name, value } = e.target
		if (name === "product_group") {
			fetchProductTypes().then((response) => {
				setProductTypes([{ id: null, name: "Todos" }, ...response])
				setProductSubtypes([])
			})
		}
		if (name === "product_type") {
			fetchProductSubtypes().then((response) => {
				setProductSubtypes([{ id: null, name: "Todos" }, ...response])
			})
		}
		fetchSales({ ...filters, [name]: value })
		setFilters((prevFilters) => ({ ...prevFilters, [name]: value }))
	}

	const handleSendEmail = useCallback(
		(rowData: any) => {
			const sellerId = rowData.items[0].product?.user?.id
			const sale = rowData
			const now = Date.now()

			if (isEmailDisabled[sellerId]) {
				toast.current?.show({
					severity: "warn",
					summary: "Aguarde",
					detail: "Por favor, aguarde antes de enviar outro e-mail",
				})
				return
			}

			if (now - (lastEmailSentTime.current[sellerId] || 0) < THROTTLE_DELAY) {
				toast.current?.show({
					severity: "warn",
					summary: "Aguarde",
					detail: "Por favor, aguarde antes de enviar outro e-mail",
				})
				return
			}

			setIsEmailDisabled((prev) => ({ ...prev, [sellerId]: true }))
			lastEmailSentTime.current[sellerId] = now

			toast.current?.show({
				severity: "info",
				summary: "Enviando",
				detail: "Enviando e-mail ...",
			})

			http
				.post(`v1/reports/send-charge-mail/${sellerId}`, {
					sale: sale,
					seller: sale?.items[0].product?.user,
					price: toMoney(sale?.payment?.mp_data?.transaction_amount),
					date: new Date(sale.created_at).toLocaleDateString(),
				})
				.then((response) => {
					if (response.status === 200) {
						toast.current?.show({
							severity: "success",
							summary: "Sucesso",
							detail: response.data.message || "E-mail enviado com sucesso",
						})
					}
				})
				.catch((error) => {
					console.error(error)
					toast.current?.show({
						severity: "error",
						summary: "Erro",
						detail: "Erro ao enviar e-mail",
					})
				})
				.finally(() => {
					setTimeout(() => {
						setIsEmailDisabled((prev) => ({ ...prev, [sellerId]: false }))
					}, THROTTLE_DELAY)
				})
		},
		[isEmailDisabled, toast],
	)

	const generateReport = async () => {
		http
			.post("v1/reports/export-sales", filters, {
				responseType: "blob",
			})
			.then((response) => {
				exportBlobToFile(response.data, "relatorio-vendas.xlsx")
				toast.current?.show({
					severity: "success",
					summary: "Sucesso",
					detail: "Relatório gerado com sucesso",
				})
			})
			.catch((error) => {
				console.log(error)
				toast.current?.show({
					severity: "error",
					summary: "Erro",
					detail: "Erro ao gerar relatório",
				})
			})
	}

	const statusTemplate = (rowData: any) => {
		const getStatusInfo = (status: number) => {
			switch (status) {
				case 1:
					return { text: "Proposta de venda", color: "text-blue-500" }
				case 2:
					return {
						text: "Aprovado, sem link de pagamento",
						color: "text-green-500",
					}
				case 3:
					return { text: "Reprovado pelo vendedor", color: "text-red-500" }
				case 4:
					return {
						text: "Link gerado, sem pagamento",
						color: "text-yellow-500",
					}
				case 5:
					return { text: "Pago", color: "text-green-700" }
				default:
					return { text: "Status desconhecido", color: "text-gray-500" }
			}
		}
		console.log(rowData?.status)
		const { text, color } = getStatusInfo(rowData?.status)

		return (
			<div className='text-center'>
				<p className={`${color} font-medium`}>{text}</p>
			</div>
		)
	}

	const dateTemplate = (field: string) => {
		return (
			<div className='text-center'>{new Date(field).toLocaleDateString()}</div>
		)
	}

	const actionTemplate = useCallback(
		(rowData: any) => {
			const sellerId = rowData.items[0].product?.user?.id
			const isDisabled = isEmailDisabled[sellerId]

			return (
				<div className='flex flex-col gap-x-3 gap-y-5'>
					<Tooltip target='.send-email-icon' />
					<i
						className={`pi pi-send send-email-icon cursor-pointer ${isDisabled ? "text-gray-400" : "text-blue-500 hover:text-blue-700"}`}
						onClick={() => !isDisabled && handleSendEmail(rowData)}
						data-pr-tooltip={
							isDisabled ? "Aguarde antes de enviar novamente" : "Enviar e-mail"
						}
						style={{ fontSize: "1.5rem", marginRight: "10px" }}
					/>
					<i
						className='pi pi-sync change-status-icon cursor-pointer text-blue-500 hover:text-blue-700'
						onClick={() => handleStatusChange(rowData)}
						data-pr-tooltip='Mudar status da venda'
						style={{ fontSize: "1.5rem" }}
					/>
				</div>
			)
		},
		[isEmailDisabled, handleSendEmail, handleStatusChange],
	)

	const items = [
		{ label: "Relatórios", url: "/reports" },
		{ label: "Relatório de Vendas" },
	]
	const home = { icon: "pi pi-home", url: "/home" }

	return (
		<div className='w-full p-10'>
			<BreadCrumb model={items} home={home} className='mb-4' />
			<Dialog
				className='p-fluid'
				header='Mudar status da venda'
				visible={dialogVisible}
				contentStyle={{ borderRadius: 0 }}
				footer={renderFooter()}
				onHide={cancelStatusChange}
			>
				<div className='flex flex-col gap-y-2'>
					<label htmlFor='status-select'>Novo Status</label>
					<Dropdown
						id='status-select'
						className='w-full'
						value={newStatus}
						options={statusOptions.filter((option) => option.value !== null)}
						onChange={(e) => setNewStatus(e.value)}
						optionLabel='public_name'
						placeholder='Selecione o novo Status'
						style={{ width: "100%" }}
					/>
				</div>
			</Dialog>
			<div className='flex flex-wrap'>
				<div className='w-4/12 xl:w-3/12 flex flex-col'>
					<label className='text-md font-bold mb-1'>Cadeia</label>
					<Dropdown
						name='product_group'
						value={filters.product_group}
						options={productGroups}
						onChange={handleFilterChange}
						optionLabel='name'
						className='!w-full'
						placeholder='Cadeia'
					/>
				</div>
				<div className='w-4/12 xl:w-3/12 flex flex-col pl-2'>
					<label className='text-md font-bold mb-1'>Tipo</label>
					<Dropdown
						name='product_type'
						value={filters.product_type}
						options={productTypes}
						onChange={handleFilterChange}
						optionLabel='name'
						className='!w-full'
						placeholder='Tipo'
					/>
				</div>
				<div className='w-4/12 xl:w-3/12 flex flex-col pl-2'>
					<label className='text-md font-bold mb-1'>Subtipo</label>
					<Dropdown
						name='product_subtype'
						value={filters.product_subtype}
						options={productSubtypes}
						onChange={handleFilterChange}
						optionLabel='name'
						className='!w-full'
						placeholder='Subtipo'
					/>
				</div>
				<div className='w-4/12 xl:w-3/12 flex flex-col pl-2'>
					<label className='text-md font-bold mb-1'>Situação</label>
					<Dropdown
						name='status'
						value={filters.status}
						options={statusOptions.filter((option) => option.value !== null)}
						onChange={handleFilterChange}
						optionLabel='public_name'
						placeholder='Situação'
						className='!w-full'
					/>
				</div>
				<div className='w-6/12 flex'>
					<div className='w-1/2 flex flex-col pr-2'>
						<label className='text-md font-bold mb-1'>Data Inicial</label>
						<InputText
							value={filters.dateInitial}
							name='dateInitial'
							onChange={handleFilterChange}
							type='date'
						/>
					</div>
					<div className='w-1/2 flex flex-col pl-2'>
						<label className='text-md font-bold mb-1'>Data Final</label>
						<InputText
							value={filters.dateFinal}
							name='dateFinal'
							onChange={handleFilterChange}
							type='date'
						/>
					</div>
					<div className='flex flex-col pl-2'>
						<label className='text-md font-bold mb-1'>&nbsp;</label>
						<Button
							label='Filtrar'
							disabled={loading}
							onClick={() => fetchSales(filters)}
							severity='success'
						/>
					</div>
					<div className='flex flex-col pl-2'>
						<label className='text-md font-bold mb-1'>&nbsp;</label>
						<Button
							label='Exportar'
							disabled={loading}
							onClick={generateReport}
							severity='success'
						/>
					</div>
				</div>
			</div>

			{loading && (
				<div className='w-full h-36 grid place-content-center p-20'>
					<Loading color='#212529' size='w-10' />
				</div>
			)}
			{!loading && sales.length === 0 && (
				<div className='w-full h-36 grid place-content-center p-20'>
					<p>Nenhuma venda encontrada com os filtros aplicados</p>
				</div>
			)}
			{!loading && sales.length > 0 && (
				<div className='h-full w-full mt-10 overflow-auto'>
					<Message
						severity='info'
						className='w-full'
						text={`Total de registros: ${sales.length}`}
					/>
					<DataTable
						showGridlines
						value={sales}
						className='w-full mt-4 font-roboto text-black'
					>
						<Column
							body={(row) => <span>{row?.user?.name}</span>}
							header='Comprador'
						/>
						<Column
							body={(row) => <span>{row?.items[0].product?.user?.name}</span>}
							header='Vendedor'
						/>
						<Column
							body={(row) => (
								<div className='flex flex-col space-y-2'>
									<p>
										<b>Cadeia:</b>{" "}
										<span>{row?.items[0].product?.product_group?.name}</span>
									</p>

									<p>
										<b>Tipo:</b>{" "}
										<span>{row?.items[0].product?.product_type?.name}</span>
									</p>

									<p>
										<b>Subtipo:</b>{" "}
										<span>{row?.items[0].product?.product_subtype?.name}</span>
									</p>
									<p>
										<b>Quantidade:</b>{" "}
										<span>
											{row?.items[0].quantity}
											{row?.items[0].unit_of_mensure}
										</span>
									</p>
									<p>
										<b>Valor do produto:</b>{" "}
										<span>{toMoney(row?.total_without_frete)}</span>
									</p>
									<p>
										<Button
											size='small'
											onClick={() => {
												window.open(
													`https://marketplace.bioconex.com.br/#/marketplace/produto?id=${row?.items[0].product.id}&key=${row?.items[0].product?.url}`,
													"_blank",
												)
											}}
										>
											Ver no marketplace
										</Button>
									</p>
								</div>
							)}
							header='Produto'
						/>

						<Column
							body={(row) => (
								<div className='flex flex-col space-y-2'>
									<p>
										<b>Cidade:</b>
										<span>
											{" "}
											{row?.address?.city?.name}/
											{row?.address?.state?.short_name}
										</span>
									</p>
									<p>
										<b>Logradouro:</b>
										<span> {row?.address?.address}</span>
									</p>
									<p>
										<b>CEP:</b>
										<span> {row?.address?.cep}</span>
									</p>
									<p>
										<b>Transportadora:</b>
										<span>
											<img
												width={100}
												src={row?.freight?.company?.picture}
												alt=''
											/>
										</span>
									</p>
									<p>
										<b>Código de rastreio:</b>
										<span> Não informado</span>
									</p>
									<p>
										<b>Valor do frete:</b>
										<span> {toMoney(row?.freight?.price)}</span>
									</p>
								</div>
							)}
							header='Entrega'
						/>
						<Column body={statusTemplate} header='Situação' />
						<Column
							body={(row) => (
								<div className='flex flex-col space-y-2'>
									<p>
										<b>Valor:</b>
										<span>
											{toMoney(row?.payment?.mp_data?.transaction_amount)}
										</span>
									</p>
									<p>
										<b>Data do pagamento:</b>
										<span>
											{dateBR({ date: row?.payment?.mp_data?.date_approved })}
										</span>
									</p>
								</div>
							)}
							header='Pagamento'
						/>
						<Column
							body={(row) => dateTemplate(row.created_at)}
							header='Data da venda'
						/>
						<Column
							body={actionTemplate}
							header='Ações'
							style={{ textAlign: "center" }}
						/>
					</DataTable>
				</div>
			)}
		</div>
	)
}

export default SalesPage

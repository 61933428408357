import { cnpj } from "cpf-cnpj-validator";
import * as Yup from "yup";


const integratorSchema = Yup.object().shape({
  integrator_name: Yup.string().nullable(),
  integrator_cpf_or_cnpj: Yup.string()
    .nullable()
    .test("cpf_or_cnpj", "CNPJ inválido", (value: any) => cnpj.isValid(value)),
  integrator_phone: Yup.string().nullable(),
  integrator_id_state: Yup.number().nullable(),
  integrator_id_city: Yup.number().nullable(),
  integrator_address: Yup.string().nullable(),
  integrator_number: Yup.string().nullable(),
  integrator_neighborhood: Yup.string().nullable(),
  integrator_cep: Yup.string().nullable(),
});

export default integratorSchema

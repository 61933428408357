import React from 'react';
import { Paginator } from 'primereact/paginator';

interface ITablePagination {
  meta: {
    current_page: number;
    last_page: number;
  },
  onChange?: (e: any) => void;
}

const TablePagination: React.FC<ITablePagination> = ({ meta, onChange }: ITablePagination) => {
  const changePage = (event: { page: number }) => {
    if (onChange) {
      onChange({ page: event.page + 1 });
    }
  };

  return (
    <div className="w-full flex justify-center mt-4">
      {meta?.last_page > 1 && (
        <Paginator
          first={meta.current_page - 1}
          rows={1}
          totalRecords={meta.last_page}
          onPageChange={changePage}
          template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          className="w-full"
        />
      )}
    </div>
  );
};

export default TablePagination;

import { createContext, useContext, useRef } from 'react';
import { Toast } from 'primereact/toast';

const defaultToastRefValue: React.RefObject<Toast> = { current: null };

const ToastContext = createContext<React.RefObject<Toast>>(defaultToastRefValue);

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const toastRef = useRef<Toast>(null);

  return (
    <ToastContext.Provider value={toastRef}>
      <Toast ref={toastRef} />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

interface ModalConfirmationProps {
  isOpen: boolean;
  toggle: () => void;
  onConfirm: () => void;
  message: string;
}

const ModalConfirmation: React.FC<ModalConfirmationProps> = ({ isOpen, toggle, onConfirm, message }) => {
  const footer = (
    <div className="w-full flex justify-end p-4 gap-x-4">
      <Button
        label="Cancelar"
        onClick={toggle}
        className="p-button-outlined p-button-secondary"
      />
      <Button
        label="Confirmar"
        onClick={() => {
          onConfirm();
          toggle();
        }}
        className="p-button-success"
      />
    </div>
  );

  return (
    <Dialog visible={isOpen} onHide={toggle} footer={footer} className="w-full md:w-2/3" modal>
      <div className="px-4 font-roboto">
        {message}
      </div>
    </Dialog>
  );
};

export default ModalConfirmation;

import { Edit } from "@styled-icons/boxicons-regular"
import { Form, Formik, type FormikProps} from "formik"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import type { KeyFilterType } from "primereact/keyfilter"
import { Message } from "primereact/message"
import { useEffect, useRef, useState } from "react"
import * as Yup from "yup"
import { fetchCities } from "../../../api/cities"
import bancos from "../../../assets/json/bancos.json"
import FormikInput from "../../../components/FormikInput"
import FormikInputMask from "../../../components/FormikInputMask"
import FormikSelect from "../../../components/FormikSelect"
import { useToast } from "../../../components/Toast"
import http from "../../../plugins/http"
import integratorSchema from "./integratorValidations"
import representativeSchema from "./representativeValidations"
import Schema from "./validations"

function ModalEditUser({
	user,
	onEdit,
	className = "",
	roles,
	states,
}: {
	user: any
	onEdit: (updateType: "user" | "integrator" | "both") => void
	className?: string
	roles: any[]
	states: any[]
}) {
	const numberFilter: KeyFilterType = "num"
	const [modal, setModal] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState("")
	const [cities, setCities] = useState<any[]>([])
	const [integratorCities, setIntegratorCities] = useState<any[]>([])
	const [schema, setSchema] = useState<any>(Schema)
	const [setCommision] = useState<number>(user.commision_percentage)
	const [isRepresentativeSelected, setIsRepresentativeSelected] =
		useState(false)
	const [role, setRole] = useState(user.role.name)

	const [initialValues, setInitialValues] = useState<any>({
		name: user.name,
		cpf_or_cnpj: user.cpf_or_cnpj,
		email: user.email,
		password: "",
		confirm_password: "",
		phone: user.phone,
		id_state: user.state?.id,
		id_city: user.city?.id,
		id_role: user.role.id,
		business_profile: user.business_profile,
		bank_name: user.bank_name,
		id_area: user.area?.id,
		bank_agency: user.bank_agency,
		commision_percentage: user.commision_percentage,
		bank_account: user.bank_account,
		pix_key: user.pix_key,
		flags: "",
	})
	const [integrationValues, setIntegrationValues] = useState({
		integrator_name: user.integration?.name || "",
		integrator_cpf_or_cnpj: user.integration?.cpf_or_cnpj || "",
		integrator_phone: user.integration?.phone || "",
		integrator_id_state: user.integration?.id_state || "",
		integrator_id_city: user.integration?.id_city || "",
		integrator_address: user.integration?.address || "",
		integrator_number: user.integration?.number || "",
		integrator_neighborhood: user.integration?.neighborhood || "",
		integrator_cep: user.integration?.cep || "",
	})

	const toast = useToast()

	const userFormRef = useRef<FormikProps<typeof initialValues>>(null)
	const integratorFormRef = useRef<FormikProps<typeof integrationValues>>(null)

	const toggle = () => {
		const isRepresentative = user.role.name === "representative"
		setRole(isRepresentative ? "Representante" : "")
		setInitialValues({
			...initialValues,
			flags: findProfile(user.flags),
		})
		setIsRepresentativeSelected(isRepresentative)
		setModal((prevModal) => !prevModal)
		if (!modal && user.id_state) {
			fetchCities({ id_state: user.id_state }).then((res) => {
				setCities(res.data)
			})
		}
	}

	const loadAddressInfo = async (e: any) => {
		let cep = e.target.value
		cep = cep.replace(/\D/g, "")
		if (cep === "") {
			integratorFormRef.current?.setFieldValue("integrator_address", "")
			integratorFormRef.current?.setFieldValue("integrator_neighborhood", "")
			integratorFormRef.current?.setFieldValue("integrator_id_state", "")
			integratorFormRef.current?.setFieldValue("integrator_id_city", "")
			return
		}
		if (cep !== "") {
			const validacep = /^[0-9]{8}$/
			if (validacep.test(cep)) {
				try {
					setLoading(true)
					const ans = await http.get(`v1/public/cep/${cep}`)
					if (ans.data && !ans.data.message) {
						integratorFormRef.current?.setFieldValue(
							"integrator_address",
							ans.data.address,
						)
						integratorFormRef.current?.setFieldValue(
							"integrator_neighborhood",
							ans.data.neighborhood,
						)
						integratorFormRef.current?.setFieldValue(
							"integrator_id_state",
							ans.data.id_state,
						)
						stateSelected(ans.data.id_state)
						integratorFormRef.current?.setFieldValue(
							"integrator_id_city",
							ans.data.id_city,
						)
					}
					setLoading(false)
				} catch (error) {
					console.error("Error fetching address:", error)
				}
			}
		}
	}

	async function handleSubmit() {
		if (userFormRef.current && integratorFormRef.current) {
			setLoading(true)
			try {
				let userUpdated = false
				let integratorUpdated = false

				if (userFormRef.current.dirty) {
					await userFormRef.current.validateForm()
					if (userFormRef.current.isValid) {
						await editUser(userFormRef.current.values)
						userUpdated = true
					}
				}

				if (integratorFormRef.current.dirty) {
					await integratorFormRef.current.validateForm()
					if (integratorFormRef.current.isValid) {
						await editUserIntegration(integratorFormRef.current.values)
						integratorUpdated = true
					}
				}

				if (userUpdated && integratorUpdated) {
					onEdit("both")
				} else if (userUpdated) {
					onEdit("user")
				} else if (integratorUpdated) {
					onEdit("integrator")
				} else {
					toast.current?.show({
						severity: "info",
						summary: "Informação",
						detail: "Nenhuma alteração foi feita",
					})
				}

				if (userUpdated || integratorUpdated) {
					toggle()
				}
			} catch (error) {
				console.error(error)
				showError("Erro ao atualizar dados")
			} finally {
				setLoading(false)
			}
		}
	}

	const flags = [
		{ label: "Produtor", value: "producer" },
		{ label: "Governo", value: "government" },
		{ label: "Indústria", value: "industry" },
		{ label: "Representante", value: "representative" },
		{ label: "Varejo", value: "retail" },
		{ label: "Atacado", value: "wholesale" },
		{ label: "Financiador", value: "funder" },
		{ label: "Outro", value: "other" },
	]

	const perfilComercial = [
		{ label: "Bebidas", value: "Bebidas" },
		{ label: "Alimentos", value: "Alimentos" },
		{ label: "Fármaco", value: "Fármaco" },
		{ label: "Varejo", value: "Varejo" },
		{ label: "Saúde", value: "Saúde" },
	]

	async function stateSelected(id: number) {
		setLoading(true)
		initialValues.id_city = ""
		fetchCities({ id_state: id }).then((res) => {
			setCities(res.data)
			setLoading(false)
		})
	}
	async function integratorStateSelected(id: number) {
		setLoading(true)
		integrationValues.integrator_id_city = ""
		fetchCities({ id_state: id }).then((res) => {
			setIntegratorCities(res.data)
			setLoading(false)
		})
	}

	function showError(message: string) {
		setError(message)
		setTimeout(() => {
			setError("")
		}, 3000)
	}

	function findProfile(flags: any) {
		if (!flags) return ""
		for (const [key, value] of Object.entries(flags)) if (value) return key
		return ""
	}

	const loadRepresentativeForm = (e: any) => {
		const role = roles.find((x) => x.value === e)
		setRole(role ? role.label : "")
		if (role.label === "Representante") {
			setSchema(representativeSchema)
			setIsRepresentativeSelected(role?.label === "Representante")
			if (user.role.name !== "representative") {
				userFormRef.current?.setFieldValue("business_profile", "")
				userFormRef.current?.setFieldValue("id_area", "")
				userFormRef.current?.setFieldValue("commision_percentage", "")
				userFormRef.current?.setFieldValue("bank_name", "")
				userFormRef.current?.setFieldValue("bank_agency", "")
				userFormRef.current?.setFieldValue("bank_account", "")
				userFormRef.current?.setFieldValue("pix_key", "")
			}
		} else {
			setSchema(Schema)
			setIsRepresentativeSelected(false)
		}
	}

	async function editUser(values: typeof initialValues) {
		try {
			if (values.password && values.password.length < 6) {
				values.password = undefined
				values.confirm_password = undefined
			}
			if (role !== "Representante") {
				values.commision_percentage = null
				values.id_area = null
				values.business_profile = null
				values.bank_name = null
				values.bank_agency = null
				values.bank_account = null
				values.pix_key = null
			}

			if (user.flags && typeof user.flags === "object") {
				const flag = values.flags
				values.flags = {}

				if (typeof flag === "string" && flag.length > 0) {
					Object.keys(user.flags).forEach((userflag) => {
						values.flags[userflag] = userflag === flag
					})

					if (values.flags[flag] === undefined) {
						values.flags[flag] = true
					}
				}
			} else {
				values.flags = {}
			}

			const response = await http.put(`/v1/managers/users/${user.id}`, {
				...values,
			})
			if (response.status !== 200) {
				throw new Error(response.data.message || "Erro ao atualizar usuário")
			}
		} catch (error) {
			console.error(error)
			throw error
		}
	}

	const editUserIntegration = async (values: any) => {
		const submissionValues = Object.keys(values).reduce((acc: any, key) => {
			const originalKey = key.replace("integrator_", "")
			acc[originalKey] = values[key]
			return acc
		}, {})

		const allFieldsFilled = Object.values(submissionValues).every(
			(value) => value !== null && value !== "",
		)
		if (allFieldsFilled) {
			try {
				let response: any
				if (user.integration !== null) {
					response = await http.put(
						`/v1/integrations/${user.integration.id}/edit-by-admin`,
						submissionValues,
					)
				} else {
					response = await http.post("/v1/integrations/store-by-admin", {
						...submissionValues,
						id_user: user.id,
					})
				}

				if (response.status !== 200 && response.status !== 201) {
					throw new Error("Erro ao processar dados do integrador")
				}
			} catch (error) {
				console.error(error)
				throw error
			}
		} else {
			throw new Error(
				"Por favor, preencha todos os campos do integrador para prosseguir.",
			)
		}
	}

	useEffect(() => {
		if (user.role.name === "representative") {
			setRole("Representante")
			setIsRepresentativeSelected(true)
		}
	}, [user.role.name])

	return (
		<div className='inline-flex'>
			<Edit
				onClick={toggle}
				size={25}
				color='#1F5974'
				className='cursor-pointer'
			/>

			<Dialog
				header='Editar usuário'
				visible={modal}
				onHide={toggle}
				className={className}
			>
				<Formik
					innerRef={userFormRef}
					initialValues={initialValues}
					validationSchema={schema}
					onSubmit={editUser}
					key={"form-edit-user"}
				>
					{({ dirty }) => (
						<Form
							className='flex flex-wrap space-y-6 p-10 pt-0'
							style={
								isRepresentativeSelected
									? { height: "40rem", overflowY: "scroll" }
									: {}
							}
						>
							<FormikInput
								className='w-full'
								name='name'
								label='Nome completo'
								placeholder='Digite o nome'
							/>
							<div className='flex w-full space-x-10'>
								<FormikSelect
									label='Tipo de usuário'
									name='id_role'
									className='w-1/2'
									defaultValue={user.id_role}
									options={roles}
									onChange={(e) => loadRepresentativeForm(e)}
								/>
								<FormikInputMask
									mask={["999.999.999-99", "99.999.999/9999-99"]}
									maskChar=''
									className='w-1/2'
									name='cpf_or_cnpj'
									label='CPF/CNPJ'
									placeholder='Digite o CPF/CNPJ'
								/>
							</div>
							<div className='flex w-full space-x-10'>
								<FormikInput
									className='w-1/2'
									name='email'
									label='Email'
									placeholder='Digite o email'
								/>
								<FormikInputMask
									className='w-1/2'
									name='phone'
									mask='(99) 99999-9999'
									label='Celular'
									placeholder='Digite o número de celular'
								/>{" "}
							</div>
							<div className='flex w-full space-x-10'>
								{states && (
									<FormikSelect
										label='Estado'
										name='id_state'
										className='w-1/2'
										defaultValue={user.id_state}
										options={states?.map((state) => ({
											label: state?.name,
											value: state?.id,
										}))}
										onChange={(e) => stateSelected(Number.parseInt(e))}
									/>
								)}
								{cities && (
									<FormikSelect
										label='Cidade'
										name='id_city'
										defaultValue={user.id_city}
										className='w-1/2'
										options={
											cities.length > 0
												? cities?.map((city) => ({
														label: city.name,
														value: city.id,
													}))
												: []
										}
									/>
								)}
							</div>
							<div className='flex w-full justify-start pr-10'>
								<FormikSelect
									label='Perfil'
									name='flags'
									className='w-1/2'
									options={flags}
								/>
							</div>
							<Message
								className='w-full mb-4'
								severity='info'
								text='Deixe a senha em branco caso não queira alterar'
							/>
							<div className='flex w-full space-x-10'>
								<FormikInput
									className='w-1/2'
									name='password'
									label='Senha de acesso'
									placeholder='Digite a senha'
									type='password'
									autoComplete='new-password'
								/>
								<FormikInput
									className='w-1/2'
									name='confirm_password'
									label='Repita a senha de acesso'
									placeholder='Digite a senha'
									type='password'
									autoComplete='new-password'
								/>
							</div>
							{role === "Representante" && (
								<>
									<hr className='w-full h-px bg-gray-300 border-0' />
									<div className='flex w-full items-start space-x-40'>
										<div className='flex items-center mr-5'>
											<h2 style={{ color: "#146600" }}>Dados Comerciais</h2>
										</div>
										<div className='flex items-center'>
											<h2
												className='font-sans Roboto font-normal ml-12'
												style={{ color: "#146600" }}
											>
												Dados Bancários
											</h2>
										</div>
									</div>
									<div className='flex w-full space-x-10'>
										<FormikSelect
											label='Perfil Comercial'
											name='business_profile'
											className='w-1/2'
											options={perfilComercial}
										/>
										<FormikSelect
											className='w-1/2'
											name='bank_name'
											label='Banco'
											placeholder='Digite o nome do banco'
											options={bancos}
										/>
									</div>
									<div className='flex w-full space-x-10'>
										<FormikSelect
											label='Área/Região'
											name='id_area'
											defaultValue='user.area.id'
											className='w-1/2'
											options={
												cities.length > 0
													? cities?.map((city) => ({
															label: city.name,
															value: city.id,
														}))
													: []
											}
										/>
										<FormikInput
											className='w-1/2'
											name='bank_agency'
											label='Agência'
											placeholder='Digite a agência'
										/>
									</div>
									<div className='flex w-full justify-end pl-10'>
										<FormikInput
											className='w-1/2'
											name='pix_key'
											label='Chave pix'
											placeholder='Digite a chave pix'
										/>
									</div>
								</>
							)}
						</Form>
					)}
				</Formik>
				<Formik
					innerRef={integratorFormRef}
					initialValues={integrationValues}
					validationSchema={integratorSchema}
					onSubmit={editUserIntegration}
					key={"form-edit-integrator"}
				>
					{({ dirty }) => (
						<Form className='flex flex-wrap space-y-6 p-10 pt-0'>
							<h2 className='font-bold mt-4 w-full'>
								Dados do perfil integrador
							</h2>
							<Message
								severity='info'
								className='w-full mt-2'
								text='Para editar os dados do perfil integrador é necessário preencher corretamente todos os campos abaixo.'
							/>
							<FormikInput
								className='w-full'
								name='integrator_name'
								label='Razão Social'
							/>
							<div className='w-full grid grid-cols-2 gap-2'>
								<FormikInputMask
									mask='99.999.999/9999-99'
									name='integrator_cpf_or_cnpj'
									label='CNPJ'
									placeholder='Digite o CNPJ'
								/>
								<FormikInputMask
									name='integrator_phone'
									mask='(99) 99999-9999'
									label='Celular'
									placeholder='Digite o número de celular'
								/>
							</div>
							<div className='flex w-full gap-x-4'>
								<div className='w-1/3'>
									{states && (
										<FormikSelect
											label='Estado'
											name='integrator_id_state'
											options={states.map((state) => ({
												label: state.name,
												value: state.id,
											}))}
											onChange={(e) =>
												integratorStateSelected(Number.parseInt(e))
											}
										/>
									)}
								</div>
								<div className='w-1/3'>
									{cities && (
										<FormikSelect
											label='Cidade'
											name='integrator_id_city'
											options={cities.map((city) => ({
												label: city.name,
												value: city.id,
											}))}
										/>
									)}
								</div>
								<div className='w-1/3'>
									<FormikInputMask
										mask='99999-999'
										name='integrator_cep'
										label='CEP'
										placeholder='Digite o CEP'
										onChange={loadAddressInfo}
									/>
								</div>
							</div>
							<div className='grid grid-cols-2 w-full gap-2'>
								<FormikInput
									className='col-span-2'
									name='integrator_address'
									label='Endereço'
								/>
								<FormikInput
									keyfilter={numberFilter}
									name='integrator_number'
									label='Número'
								/>
								<FormikInput name='integrator_neighborhood' label='Bairro' />
							</div>
						</Form>
					)}
				</Formik>

				<div className='w-full flex justify-end p-4 gap-x-4'>
					<Button
						onClick={toggle}
						disabled={loading}
						severity='danger'
						outlined
					>
						Cancelar
					</Button>
					<Button
						type='button'
						severity='success'
						onClick={handleSubmit}
						loading={loading}
					>
						Atualizar
					</Button>
				</div>

				{error !== "" && (
					<Message className='w-full mt-4' severity='error'>
						{error}
					</Message>
				)}
			</Dialog>
		</div>
	)
}

export default ModalEditUser
